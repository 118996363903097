<template>
  <div class="page-container">
    <!--年级和班级名称-->
    <div class="page-title flex flex-between">
      <div class="left">
        <span class="dot"></span>
        <span class="title">{{ title }}</span>
      </div>
      <div class="right">
        <el-button type="primary" size="small" @click="ListMethods().clickExportLearnLogBtn()" :loading="exportLearnLogLoading"
                   v-if="learnRecordSchoolList.indexOf(userInfo.schoolId)>-1">导出学习记录</el-button>
        <el-button type="success" size="small" @click="ListMethods().clickExportScoreBtn()">导出成绩</el-button>
      </div>
    </div>
    <!--筛选-->
    <div class="filter-container clearFix">
      <list-search-filter :search-filter="lists.searchFilter"
                          @clickSearchFilterBtn="query=>ListMethods().clickSearchFilterBtn(query)">
        <!--  操作  -->
        <div slot="right-container">
        </div>
      </list-search-filter>
    </div>
    <!--列表-->
    <div class="table-container">
      <el-table :header-cell-style="{background:'#fafafa',color:'#555'}" :data="lists.list" v-loading="lists.loading"
                element-loading-text="加载中" fit
                style="width: 100%;" @sort-change="sort=>ListMethods().sortChange(sort)">
        <el-table-column label="账号" prop="studentaccount" align="center" :sortable="'custom'">
          <template slot-scope="scope">
            <span>{{ scope.row.studentaccount }}</span>
          </template>
        </el-table-column>
        <el-table-column label="姓名" align="center">
          <template slot-scope="scope">
            <span>{{ scope.row.studentname }}</span>
          </template>
        </el-table-column>
        <el-table-column label="行政班级" align="center">
          <template slot-scope="scope">
            <span>{{ scope.row.gradeName }} - {{ scope.row.administrationClazzName }}</span>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="experimentscore" :sortable="'custom'" width="200">
          <template slot="header" slot-scope="scope">
           <span>实验分数
              <el-tooltip class="item" effect="dark" content="若多次实验，显示最高分" placement="top">
                <i class="el-icon-warning"></i>
              </el-tooltip>
           </span>
          </template>
          <template slot-scope="scope">
            <div class="experiment-score">
              <span v-if="scope.row.isfinishexp">{{ scope.row.experimentscore | numberFormat }}</span>
              <el-dropdown v-if="scope.row.studentScoreEntities.length>0">
                <span class="el-dropdown-link">
                  <i class="el-icon-arrow-down el-icon--right"></i>
                </span>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item disabled v-for="(item,index) in scope.row.studentScoreEntities" :key="index">
                    <span :class="item.score==scope.row.experimentscore?'light':''">{{
                        item.updatetime | dateFormat
                      }}的实验分数 {{ item.score | numberFormat }}分</span>
                  </el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </div>
            <span v-if="!scope.row.isfinishexp">--</span>
          </template>
        </el-table-column>
        <el-table-column label="学习次数" align="center"  width="200"  v-if="learnRecordSchoolList.indexOf(userInfo.schoolId)>-1">
          <template slot-scope="scope">
            <span class="">
              <span>{{scope.row.loginNum}}次</span>
              <span><el-button type="text" size="mini" @click="clickViewLoginLog(scope.row)">查看</el-button></span>
            </span>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="labreportscore" :sortable="'custom'" width="200">
          <template slot="header" slot-scope="scope">
           <span>实验报告
              <el-tooltip class="item" effect="dark" content="" placement="top">
                <i class="el-icon-warning"></i>
              </el-tooltip>
           </span>
          </template>
          <template slot-scope="scope">
            <span v-if="scope.row.labreportstatus">{{ scope.row.labreportscore | numberFormat }}</span>
            <span v-if="!scope.row.labreportstatus&&scope.row.labreportcontent">待批改</span>
            <span v-if="!scope.row.labreportcontent">未提交</span>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="totalscore" :sortable="'custom'" width="200">
          <template slot="header" slot-scope="scope">
           <span>综合分数
              <el-tooltip class="item" effect="dark" content="综合分数=实验分*0.4+实验报告分*0.6" placement="top">
                <i class="el-icon-warning"></i>
              </el-tooltip>
           </span>
          </template>
          <template slot-scope="scope">
            <span v-if="scope.row.isfinishexp">{{ scope.row.totalscore | numberFormat }}</span>
            <span v-if="!scope.row.isfinishexp">--</span>
          </template>
        </el-table-column>
        <el-table-column align="center" label="操作" width="250"
                         class-name="small-padding fixed-width">
          <template slot-scope="scope">
            <el-button type="text" size="mini" round v-if="scope.row.labreportcontent"
                       @click="e=>ListMethods().clickViewBtn(scope.row,scope.$index)">批改实验报告
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!--列表分页-->
    <div class="pagination-container">
      <el-pagination background @current-change="(number)=>ListMethods().pageChange(number)"
                     :current-page.sync="lists.pages.number" :page-size.sync="lists.pages.size"
                     layout="total,prev, pager, next,sizes,jumper" :total="lists.pages.totalElements"
                     @size-change="(size)=>ListMethods().pageLimitChange(size)"
                     :page-count="lists.pages.totalPages">
      </el-pagination>
    </div>
    <!--详情弹窗-->
    <el-dialog
        :close-on-click-modal="false"
        :title="entityInfo.title"
        :visible.sync="entityInfo.dialog"
        width="1000px"
        center
        v-el-drag-dialog>
      <div class="dialog-container report-container">
        <div class="header flex flex-between">
          <div class="li">
            <span class="title">姓名：</span>
            <span class="content">{{ entityInfo.edit.studentname }}</span>
          </div>
          <div class="li">
            <span class="title">账号：</span>
            <span class="content">{{ entityInfo.edit.studentaccount }}</span>
          </div>
          <div class="li">
            <span class="title">教学班：</span>
            <span class="content">{{ clazzName }}</span>
          </div>
          <div class="li">
            <span class="title">报告提交时间：</span>
            <span class="content">{{ entityInfo.edit.labreportTime | dateFormat }}</span>
          </div>
        </div>
        <div class="text-container html-view limit-height" v-html="entityInfo.edit.labreportcontent"></div>
        <div class="footer flex flex-between">
          <div class="left flex flex-start">
            <span class="title">分数(0到100)：</span>
            <el-input style="width: 200px" type="number" v-model.number="entityInfo.edit.labreportscore"></el-input>
          </div>
          <div class="right flex flex-end">
            <div style="color: #999">
              批阅教师:<span v-if="entityInfo.edit.labreportcheckman">
              {{ entityInfo.edit.labreportcheckman }}{{
                " "
              }}{{ entityInfo.edit.labreportchecktime | dateFormat }}
              </span>
              <span v-else>
                {{ userInfo.username }}{{
                  " "
                }}{{ new Date() | dateFormat }}
              </span>
            </div>
          </div>
        </div>
      </div>
      <div slot="footer" class="dialog-footer">
        <div class="flex flex-around">
          <div>
            <el-button type="default" size="small"
                       @click="EntityInfoMethods().clickReportPrevBtn()">上一个报告
            </el-button>
            <el-button type="default" size="small"
                       @click="EntityInfoMethods().clickNoReportPrevBtn()">上一个未批改的报告
            </el-button>
          </div>
          <div>
            <el-button type="default" size="small"
                       @click="entityInfo.dialog=false">取 消
            </el-button>
            <el-button type="primary" size="small"
                       @click="EntityInfoMethods().clickSureBtn()">确认批改
            </el-button>
          </div>
          <div>
            <el-button type="default" size="small"
                       @click="EntityInfoMethods().clickNoReportNextBtn()">下一个未批改的报告
            </el-button>
            <el-button type="default" size="small"
                       @click="EntityInfoMethods().clickReportNextBtn()">下一个报告
            </el-button>
          </div>
        </div>
      </div>
    </el-dialog>
    <!--导入input-->
    <input
        id="importFile"
        type="file"
        style="display: none"
        @change="(files)=>{ListMethods().importFileChange(files)}"
    >
    <!-- 学习记录弹窗 -->
    <el-dialog
      title="学习记录"
      :visible.sync="loginLogDialog.visible"
      width="900px"
      v-el-drag-dialog
      center>
      <div v-loading="loginLogDialog.loading">
        <!-- 学生基本信息 -->
        <div class="student-info">
          <div class="info-item">
            <span class="label">姓名:</span>
            <span>{{loginLogDialog.studentInfo.studentname}}</span>
          </div>
          <div class="info-item">
            <span class="label">账号:</span>
            <span>{{loginLogDialog.studentInfo.studentaccount}}</span>
          </div>
          <div class="info-item">
            <span class="label">教学班:</span>
            <span>{{this.clazzName}}</span>
          </div>
          <div class="info-item">
            <span class="label">行政班:</span>
            <span>{{ loginLogDialog.studentInfo.gradeName }} - {{ loginLogDialog.studentInfo.administrationClazzName }}</span>
          </div>
          <div class="info-item">
            <span class="label">实验项目:</span>
            <span>{{this.experimentName}}</span>
          </div>
        </div>

        <!-- 学习记录表格 -->
        <el-table
          :data="loginLogDialog.list"
          style="width: 100%"
          :header-cell-style="{background:'#fafafa',color:'#555'}"
          border>
          <el-table-column
            prop="createTime"
            label="登录时间"
            align="center"
            width="180">
            <template slot-scope="scope">
              {{scope.row.createTime | dateFormat}}
            </template>
          </el-table-column>
          <el-table-column
              prop="createTime"
              label="是否完成学习"
              align="center"
              width="180">
            <template slot-scope="scope">
              <span v-if="scope.row.scoreTime">是</span>
              <span v-else>否</span>
            </template>
          </el-table-column>
          <el-table-column
              prop="scoreTime"
              label="传分时间"
              align="center"
              width="180">
            <template slot-scope="scope">
              <span v-if="scope.row.scoreTime"> {{scope.row.scoreTime | dateFormat}}</span>
              <span v-else>-</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="score"
            align="center"
            label="本次得分">
            <template slot-scope="scope">
             <span v-if="scope.row.scoreTime"> {{scope.row.score | numberFormat}}</span>
              <span v-else>-</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="usedTime"
            align="center"
            label="学习用时">
            <template slot-scope="scope">
              <span v-if="scope.row.scoreTime">
              {{scope.row.usedTime*1000 | minuteSecondFilter}}
              </span>
              <span v-else>-</span>
            </template>
          </el-table-column>

        </el-table>
      </div>
    </el-dialog>
    <!-- 添加导出学习记录弹窗 -->
    <el-dialog
        :close-on-click-modal="false"
        title="导出学习记录"
        :visible.sync="exportLearnLogDialog.visible"
        width="500px"
        center
        v-el-drag-dialog>
      <div class="dialog-container">
        <!-- 统计信息 -->
        <div v-if="exportLearnLogDialog.result.length" class="statistics-container">
          <div class="statistics-info">
            共查询到 <span class="highlight">{{ exportLearnLogDialog.result.length }}</span> 条学习记录
          </div>
        </div>
        
        <!-- 按钮区域 -->
        <div class="search-btn-container">
          <el-button type="primary" @click="LearnLogMethods().search()" :loading="exportLearnLogDialog.loading">
            查询
          </el-button>
          <el-button type="success" @click="LearnLogMethods().exportExcel()" 
                     :disabled="!exportLearnLogDialog.result.length"
                     :loading="exportLearnLogDialog.exportLoading">
            导出表格
          </el-button>
          <el-button type="warning" @click="LearnLogMethods().analyzeData()"
                     :disabled="!exportLearnLogDialog.result.length">
            数据分析
          </el-button>
        </div>
      </div>
    </el-dialog>
    <!-- 添加数据分析弹窗 -->
    <el-dialog
        :close-on-click-modal="false"
        title="学习记录数据分析"
        :visible.sync="analysisDialog.visible"
        width="1200px"
        center
        v-el-drag-dialog>
      <div class="analysis-container">
        <!-- 统计卡片区域 -->
        <div class="statistics-cards">
          <el-row :gutter="20">
            <el-col :span="8">
              <el-card shadow="hover">
                <div class="stat-item">
                  <div class="stat-title">学生数量</div>
                  <div class="stat-value">{{ analysisDialog.stats.studentCount }}</div>
                </div>
              </el-card>
            </el-col>
            <el-col :span="8">
              <el-card shadow="hover">
                <div class="stat-item">
                  <div class="stat-title">行政班数量</div>
                  <div class="stat-value">{{ analysisDialog.stats.adminClazzCount }}</div>
                </div>
              </el-card>
            </el-col>
            <el-col :span="8">
              <el-card shadow="hover">
                <div class="stat-item">
                  <div class="stat-title">完成学习数 / 总记录数 </div>
                  <div class="stat-value">  {{  analysisDialog.stats.finishedCount }} / {{ analysisDialog.stats.totalCount }}</div>
                  <div class="stat-sub">完成率: {{ analysisDialog.stats.finishRate }}%</div>
                </div>
              </el-card>
            </el-col>
          </el-row>

          <el-row :gutter="20" style="margin-top: 20px">
            <el-col :span="12">
              <el-card shadow="hover">
                <div class="stat-item">
                  <div class="stat-title">得分统计</div>
                  <div class="stat-value">平均 {{ analysisDialog.stats.avgScore }} 分</div>
                  <div class="stat-sub">最高: {{ analysisDialog.stats.maxScore }}分 / 最低: {{ analysisDialog.stats.minScore }}分</div>
                </div>
              </el-card>
            </el-col>
            <el-col :span="12">
              <el-card shadow="hover">
                <div class="stat-item">
                  <div class="stat-title">用时统计</div>
                  <div class="stat-value">平均: {{ analysisDialog.stats.avgTime }}</div>
                  <div class="stat-sub">最长: {{ analysisDialog.stats.maxTime }}</div>
                  <div class="stat-sub">最短: {{ analysisDialog.stats.minTime }}</div>
                  <div class="stat-sub">总计: {{ analysisDialog.stats.totalTime }}</div>
                </div>
              </el-card>
            </el-col>
          </el-row>
        </div>

        <!-- 图表区域 -->
        <div class="chart-container" style="margin-top: 30px">
          <div ref="learningChart" style="width: 100%; height: 400px"></div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import ListSearchFilter from '@/components/list/listSearchFilter'
import {EnumsModel} from '@/model/EnumsModel'
import elDragDialog from '@/directive/el-drag-dialog'
import {msg_confirm, msg_confirm_simple, msg_err, msg_input, msg_success} from '@/utils/ele_component'
import {date_format, find_obj_from_arr_by_id, objectToLVArr} from '@/utils/common'
import {mapState} from 'vuex'
import {StudentModel} from "@/model/exp/StudentModel";
import {dateFormat,dateFormat2, minuteSecondFilter, numberFormat} from "@/filter/filter";
import {ScoreModel} from "@/model/exp/ScoreModel";
import {ClazzModel} from "@/model/exp/ClazzModel";
import {CommonModel} from "@/model/CommonModel";
import {LoginLogModel} from "@/model/exp/LoginLogModel";
import {excel_export_from_json} from "@/utils/excel";
import * as echarts from 'echarts'
import {learnRecordSchoolList} from "@/config/ExpAuthSchoolConfig";
export default {
  name: 'teacherScoreInfo',
  components: {ListSearchFilter},
  directives: {
    elDragDialog
  },
  // 过滤器
  filters: {
    dateFormat: dateFormat2,
    numberFormat: numberFormat,
    minuteSecondFilter:minuteSecondFilter,
  },
  computed: {
    ...mapState({
      userInfo: state => state.user
    })
  },
  data() {
    return {
      // 权限-可以使用学习记录的学校
      learnRecordSchoolList,
      clazzId: this.$route.query["clazzid"],
      clazzName: this.$route.query["clazzname"],
      experimentId: this.$route.query["experimentid"],
      experimentName: this.$route.query["experimentname"],
      semesterId: this.$route.query["semesterid"],
      scheduleId: this.$route.query["scheduleId"],// 教师实验安排id
      title: this.$route.query["title"],
      // 外部方法
      date_format: date_format,
      // 枚举
      enums: EnumsModel,
      // 列表
      lists: {
        list: [],
        loading: false,
        query: {
          labreportstatus: ""
        },
        queryBase: {},
        pages: {
          size: 20
        },
        searchFilter: {
          search: [
            {
              type: 'input',
              label: '请输入学生姓名、账号',
              key: 'search',
              value: ''
            }
          ],
          filter: [
            {
              type: 'select',
              label: '行政班',
              key: 'administrationClazzId',
              hideTitle: true,
              value: '',
              data: [],
              dataObject: {},
              dataOrigin: [],// 存储数据库返回的默认列表
              change: function (value) {
              }
            },
            {
              type: 'select',
              label: '实验报告',
              key: 'labreportstatus',
              hideTitle: true,
              value: '',
              data: [
                {label: "全部", value: ""},
                {label: "未批改", value: 'false'},
                {label: "已批改", value: 'true'}
              ],
              dataObject: {},
              dataOrigin: [],// 存储数据库返回的默认列表
              change: function (value) {
              }
            },
          ]
        }
      },
      // 实体详情
      entityInfo: {
        dialog: false,
        scoreChange: false,// 分数是否改变
        edit: {
          labreportscore: ""
        },
        // 输入检测
        formRules: {
          'labreportscore': {required: true}
        },
      },
      // 学习记录弹窗
      loginLogDialog: {
        visible: false,
        loading: false,
        studentInfo: {},
        list: []
      },
      // 是否正在导出学习记录
      exportLearnLogLoading: false,
      // 添加导出学习记录弹窗
      exportLearnLogDialog: {
        visible: false,
        loading: false,
        exportLoading: false,
        result: []
      },
      analysisDialog: {
        visible: false,
        stats: {
          studentCount: 0,
          adminClazzCount: 0,
          totalCount: 0,
          finishedCount: 0,
          finishRate: '0.0',
          maxScore: 0,
          minScore: 0,
          avgScore: 0,
          maxTime: '-',
          minTime: '-',
          avgTime: '-',
          totalTime: '-'
        },
        chart: null
      }
    }
  },
  async mounted() {
    // 设置基础查询
    if (this.clazzId) {
      this.lists.queryBase = {
        clazzid: this.clazzId,
        experimentid: this.experimentId,
        semesterid: this.semesterId,
        scheduleid: this.scheduleId,
      }
      this.ListMethods().getAdministrationClazzFilterList();
    }
    // 获取列表
    this.ListMethods().getList(1, this.lists.pages.size, this.lists.query)
    // 初始化筛选
    this.ListMethods().initFilter()
  },
  methods: {
    // 列表Methods
    ListMethods() {
      let $this = this
      return {
        // 生成行政班筛选列表
        async getAdministrationClazzFilterList() {
          let clazzInfo = await ClazzModel.getOne($this.clazzId)
          let options = [];// [{label:"","value":{}]
          let optionsObject = {};// {"value":"label"}
          let administrationClazzIdsArr = clazzInfo.administrationClazzIds.split("~")
          let administrationClazzNamesArr = clazzInfo.administrationClazzNames.split("~")
          // 新增全部
          options.push({
            label: "全部",
            value: ""
          })
          optionsObject[""] = "全部";
          administrationClazzIdsArr.forEach((li, index) => {
            options.push({
              label: administrationClazzNamesArr[index],
              value: li
            })
            optionsObject[li] = administrationClazzNamesArr[index];
          })
          $this.$set($this.lists.searchFilter.filter[0], "data", options)
          $this.$set($this.lists.searchFilter.filter[0], "dataObject", optionsObject)
        },
        // 获取列表
        async getList(page, size, query) {
          console.log(query)
          query = Object.assign(query, $this.lists.queryBase)
          $this.lists.loading = true;
          if (!query.sort) {
            query.sort = "studentaccount,asc"
          }
          [$this.lists.list, $this.lists.pages] = await ScoreModel.getInfoList(page, size, query)
          $this.lists.loading = false
        },
        // 分页-改变页码
        async pageChange(page) {
          await this.getList(page, $this.lists.pages.size, $this.lists.query)
        },
        // 分页-改变每页显示数量
        async pageLimitChange(size) {
          this.getList($this.lists.pages.number, size, $this.lists.query)
        },
        // 排序被改变
        async sortChange(sort) {
          let querySort = $this.lists.query.sort
          querySort = CommonModel.elementTableSort(sort)
          $this.$set($this.lists.query, "sort", querySort)
          this.getList(1, $this.lists.pages.size, $this.lists.query)
        },
        // 初始化筛选列表
        async initFilter(type) {
        },
        // 点击搜索按钮
        clickSearchFilterBtn(query) {
          this.getList(1, $this.lists.pages.size, query)
          $this.lists.query = query
        },
        // 点击删除按钮
        async clickDeleteBtn(entity, index) {
          if (await msg_confirm("确认要删除该学生吗？")) {
            if (await StudentModel.remove([entity.id], $this.clazzId)) {
              msg_success("删除成功")
              $this.ListMethods().getList($this.lists.pages.number, $this.lists.pages.size, $this.lists.query)
            } else {
              msg_err("删除失败")
            }
          }
        },
        // 点击详情按钮
        clickViewBtn(entity, $index) {
          if (entity.labreportcontent) {
            $this.entityInfo.title = $this.experimentName + "实验报告"
            $this.entityInfo.edit = JSON.parse(JSON.stringify(entity))
            $this.entityInfo.edit.labreportscoreOld = $this.entityInfo.edit.labreportscore // 记录旧数值，判断是否改变
            $this.entityInfo.dialog = true;
            $this.entityInfo.$index = $index;
          } else {
            msg_err("该学生尚未提交实验报告")
          }
        },
        // 点击导出按钮
        async clickExportBtn() {
          let search = $this.lists.query.search
          let labreportstatus = $this.lists.query.labreportstatus
          let administrationClazzId = $this.lists.query.administrationClazzId
          ScoreModel.export($this.scheduleId, search, labreportstatus, administrationClazzId,$this.title)
        },
        // 实验报告状态改变
        async labStatusChange(v) {
          $this.lists.query.labreportstatus = !v
          this.getList(1, $this.lists.pages.size, $this.lists.query)
        },
        // 点击导出成绩按钮
        async clickExportScoreBtn() {
          msg_confirm_simple("正在导出成绩，请稍等...")
          let search = $this.lists.query.search
          let labreportstatus = $this.lists.query.labreportstatus
          let administrationClazzId = $this.lists.query.administrationClazzId
          ScoreModel.export($this.scheduleId, search, labreportstatus, administrationClazzId,$this.title)
        },
        // 点击导出学习记录按钮
        async clickExportLearnLogBtn() {
          $this.exportLearnLogDialog.visible = true
        },
      }
    },
    // 实体Methods
    EntityInfoMethods() {
      let $this = this;
      return {
        // 点击确定按钮
        async clickSureBtn() {
          let value = $this.entityInfo.edit.labreportscore
          let validate = true
          if (value < 0) {
            msg_err('请输入0-100内正整数作为分数')
            validate = false
          }
          if (value === "") {
            msg_err('请输入0-100内正整数作为分数')
            validate = false
          }
          if (value > 100) {
            msg_err('请输入0-100正整数作为分数')
            validate = false
          }
          // 判断是否改变
          if (value === $this.entityInfo.edit.labreportscoreOld && value !== 0) {
            msg_err('分数未做修改！')
            return
          }
          if (validate) {
            let result = await ScoreModel.modifyLapScore({
              id: $this.entityInfo.edit.id,
              labreportscore: value,
            })
            if (result) {
              msg_success("修改实验分数成功")
              // 修改列表栏里的相关分数
              $this.$set($this.lists.list[$this.entityInfo.$index], "labreportscore", result.labreportscore)
              $this.$set($this.lists.list[$this.entityInfo.$index], "labreportstatus", true)
              $this.$set($this.lists.list[$this.entityInfo.$index], "totalscore", result.totalscore)
            }
          }
        },
        // 点击上一个未批改报告按钮
        async clickNoReportPrevBtn() {
          let list = $this.lists.list;
          let entity = {}
          let nowIndex = $this.entityInfo.$index
          for (let i = nowIndex - 1; i >= 0; i--) {
            let li = list[i]
            if (!li.labreportstatus && li.labreportcontent) {// 找到上一个批改的报告
              entity = li
              nowIndex = i
              break
            }
          }
          if (entity.hasOwnProperty("labreportcontent")) {
            $this.ListMethods().clickViewBtn(entity, nowIndex)
          } else {
            // 翻页
            if ($this.lists.pages.number > 1) {
              await $this.ListMethods().pageChange($this.lists.pages.number - 1)
              $this.entityInfo.$index = $this.lists.pages.size
              this.clickNoReportPrevBtn()
            } else {
              msg_err("没有上一个未批改的报告了！")
            }
          }
        },
        // 点击上一个报告按钮
        async clickReportPrevBtn() {
          let list = $this.lists.list;
          let entity = {}
          let nowIndex = $this.entityInfo.$index
          for (let i = nowIndex - 1; i >= 0; i--) {
            let li = list[i]
            if (li.labreportcontent) {// 找到上一个的报告
              entity = li
              nowIndex = i
              break
            }
          }
          if (entity.hasOwnProperty("labreportcontent")) {
            $this.ListMethods().clickViewBtn(entity, nowIndex)
          } else {
            // 翻页
            if ($this.lists.pages.number > 1) {
              await $this.ListMethods().pageChange($this.lists.pages.number - 1)
              $this.entityInfo.$index = $this.lists.pages.size
              this.clickReportPrevBtn()
            } else {
              msg_err("没有上一个报告了！")
            }
          }
        },
        // 点击下一个未批改报告按钮
        async clickNoReportNextBtn() {
          let list = $this.lists.list;
          let entity = {}
          let nowIndex = $this.entityInfo.$index
          for (let i = nowIndex + 1; i < list.length; i++) {
            let li = list[i]
            if (!li.labreportstatus && li.labreportcontent) {// 找到下一个未批改的报告
              entity = li
              nowIndex = i
              break
            }
          }
          if (entity.hasOwnProperty("labreportcontent")) {
            $this.ListMethods().clickViewBtn(entity, nowIndex)
          } else {// 未找到下一个
            // 翻页
            if ($this.lists.pages.number < $this.lists.pages.totalPage) {
              await $this.ListMethods().pageChange($this.lists.pages.number + 1)
              $this.entityInfo.$index = -1;
              this.clickNoReportNextBtn()
            } else {
              msg_err("没有下一个未批改的报告了！")
            }
          }
        },
        // 点击下一个报告按钮
        async clickReportNextBtn() {
          let list = $this.lists.list;
          let entity = {}
          let nowIndex = $this.entityInfo.$index
          for (let i = nowIndex + 1; i < list.length; i++) {
            let li = list[i]
            if (li.labreportcontent) {// 找到下一个的报告
              entity = li
              nowIndex = i
              break
            }
          }
          if (entity.hasOwnProperty("labreportcontent")) {
            $this.ListMethods().clickViewBtn(entity, nowIndex)
          } else {// 未找到下一个
            // 翻页
            if ($this.lists.pages.number < $this.lists.pages.totalPage) {
              await $this.ListMethods().pageChange($this.lists.pages.number + 1)
              $this.entityInfo.$index = -1;
              this.clickReportNextBtn()
            } else {
              msg_err("没有下一个报告了！")
            }
          }
        }
      }
    },
    // 点击查看学习记录
    async clickViewLoginLog(row) {
      this.loginLogDialog.studentInfo = row;
      this.loginLogDialog.visible = true;
      this.loginLogDialog.loading = true;
      
      // 获取学习记录列表
      this.loginLogDialog.list = await LoginLogModel.getList({
        scheduleId: this.scheduleId, // 实验id
        studentExperimentId: row.id, // 实验记录id
        status:0,// 成功的项目
        type:2,// 实验项目
      });
      
      this.loginLogDialog.loading = false;
    },
    // 添加学习记录相关方法
    LearnLogMethods() {
      let $this = this
      return {
        // 查询数据
        async search() {
          $this.exportLearnLogDialog.loading = true
          try {
            // 获取学习记录列表
            let loginLogs = await LoginLogModel.getList({
              scheduleId: $this.scheduleId,
              status: 0,
              type: 2,
            })

            // 将学生信息加到记录中
            let [lists, ] = await ScoreModel.getInfoList(1, -1, $this.lists.queryBase)
            let studentMap = {}
            lists.forEach(student => {
              studentMap[student.id] = {
                studentname: student.studentname,
                studentaccount: student.studentaccount,
                clazzName: $this.clazzName,
                experimentName: $this.experimentName,
                administrationClazzName: student.gradeName + ' - ' + student.administrationClazzName
              }
            })

            $this.exportLearnLogDialog.result = loginLogs.map(log => ({
              ...log,
              ...studentMap[log.studentExperimentId] || {}
            }))
          } catch (error) {
            console.error('查询失败:', error)
            msg_err('查询失败')
          } finally {
            $this.exportLearnLogDialog.loading = false
          }
        },

        // 导出Excel
        async exportExcel() {
          if (!$this.exportLearnLogDialog.result.length) {
            msg_err('没有可导出的数据')
            return
          }

          $this.exportLearnLogDialog.exportLoading = true
          msg_confirm_simple("正在导出学习记录，请稍等...")

          try {
            const header = ['学生账号', '学生姓名', '教学班级', '行政班级', '实验项目', '登录时间', '是否完成学习', '传分时间', '本次得分', '学习用时']
            const filter = ['studentaccount', 'studentname', 'clazzName', 'administrationClazzName', 'experimentName', 'createTime', 'hasScore', 'scoreTime', 'score', 'usedTime']

            function formatJson(filterVal, jsonData) {
              return jsonData.map(v => filterVal.map(j => {
                let value = ''
                switch (j) {
                  case 'createTime':
                  case 'scoreTime':
                    value = v[j] ? date_format(v[j], "yyyy-MM-dd HH:mm:ss") : '-'
                    break
                  case 'hasScore':
                    value = v.scoreTime ? '是' : '否'
                    break
                  case 'score':
                    value = v.scoreTime ? v[j] : '-'
                    break
                  case 'usedTime':
                    value = v.scoreTime ? minuteSecondFilter(v[j] * 1000) : '-'
                    break
                  default:
                    value = v[j] || '-'
                }
                return value
              }))
            }

            excel_export_from_json(
              $this.exportLearnLogDialog.result,
              header,
              filter,
              formatJson,
              $this.title + "-学习记录-" + date_format(new Date(), "yyyy-MM-dd HH:mm:ss")
            )
          } finally {
            $this.exportLearnLogDialog.exportLoading = false
          }
        },

        // 数据分析
        analyzeData() {
          const data = $this.exportLearnLogDialog.result
          if (!data || data.length === 0) {
            msg_err('没有可分析的数据')
            return
          }

          // 1. 基础统计
          const stats = {
            studentCount: new Set(data.map(item => item.studentaccount)).size,
            adminClazzCount: new Set(data.map(item => item.administrationClazzName)).size,
            totalCount: data.length,
            finishedCount: data.filter(item => item.scoreTime).length
          }
          stats.finishRate = ((stats.finishedCount / stats.totalCount) * 100).toFixed(1)

          // 2. 完成学习的数据分析
          const finishedData = data.filter(item => item.scoreTime)
          if (finishedData.length > 0) {
            stats.maxScore = Math.max(...finishedData.map(item => item.score))
            stats.minScore = Math.min(...finishedData.map(item => item.score))
            stats.avgScore = (finishedData.reduce((sum, item) => sum + item.score, 0) / finishedData.length).toFixed(1)

            const formatTime = (seconds) => {
              const hours = Math.floor(seconds / 3600)
              const minutes = Math.floor((seconds % 3600) / 60)
              const secs = seconds % 60
              return `${hours}小时${minutes}分${secs}秒`
            }

            const usedTimes = finishedData.map(item => item.usedTime)
            stats.maxTime = formatTime(Math.max(...usedTimes))
            stats.minTime = formatTime(Math.min(...usedTimes))
            stats.avgTime = formatTime(Math.floor(usedTimes.reduce((sum, time) => sum + time, 0) / usedTimes.length))
            stats.totalTime = formatTime(usedTimes.reduce((sum, time) => sum + time, 0))
          }

          // 3. 图表数据
          $this.analysisDialog.stats = stats
          $this.analysisDialog.visible = true

          $this.$nextTick(() => {
            if ($this.analysisDialog.chart) {
              $this.analysisDialog.chart.dispose()
            }
            $this.analysisDialog.chart = echarts.init($this.$refs.learningChart)

            const dateMap = new Map()
            data.forEach(item => {
              const date = date_format(item.createTime, "yyyyMMdd")
              if (!dateMap.has(date)) {
                dateMap.set(date, { total: 0, finished: 0 })
              }
              dateMap.get(date).total++
              if (item.scoreTime) {
                dateMap.get(date).finished++
              }
            })

            const dates = Array.from(dateMap.keys()).sort()
            const option = {
              title: {
                text: '每日学习情况统计',
                left: 'center'
              },
              tooltip: {
                trigger: 'axis',
                axisPointer: {
                  type: 'cross',
                  crossStyle: {
                    color: '#999'
                  }
                }
              },
              legend: {
                data: ['学习人数', '完成人数'],
                bottom: 10
              },
              xAxis: {
                type: 'category',
                data: dates.map(date => date.replace(/(\d{4})(\d{2})(\d{2})/, '$1-$2-$3')),
                axisPointer: {
                  type: 'shadow'
                }
              },
              yAxis: {
                type: 'value',
                name: '人数',
                minInterval: 1
              },
              series: [
                {
                  name: '学习人数',
                  type: 'bar',
                  data: dates.map(date => dateMap.get(date).total),
                  barMaxWidth: 50
                },
                {
                  name: '完成人数',
                  type: 'line',
                  data: dates.map(date => dateMap.get(date).finished)
                }
              ]
            }

            $this.analysisDialog.chart.setOption(option)
          })
        }
      }
    }
  },
  watch: {
    'analysisDialog.visible'(val) {
      if (!val && this.analysisDialog.chart) {
        this.analysisDialog.chart.dispose()
        this.analysisDialog.chart = null
      }
    }
  }
}
</script>

<style scoped lang="less">
.page-title {
  margin-bottom: 10px;
  border-bottom: 1px solid #f8f8f8;
  padding-bottom: 10px;

  .left {
    .dot {
      width: 10px;
      height: 10px;
      border-radius: 5px;
      background-color: #2d7bc9;
      margin-right: 10px;
      display: inline-block;
    }

    .title {
      font-size: 15px;
      color: #666;
    }
  }

  .right {

  }
}


.text-container {
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 10px;
  background: #F5F5F5;
}

.report-container {
  .footer {

  }
}

.student-info {
  margin-bottom: 20px;
  padding: 15px;
  background: #f5f7fa;
  border-radius: 4px;

  .info-item {
    display: inline-block;
    margin-right: 30px;
    
    .label {
      color: #606266;
      margin-right: 10px;
    }
  }
}

.dialog-container {
  padding: 0 20px;
  
  .statistics-container {
    background-color: #f5f7fa;
    padding: 15px;
    border-radius: 4px;
    margin-bottom: 20px;
    
    .statistics-info {
      text-align: center;
      
      .highlight {
        color: #409EFF;
        font-weight: bold;
        margin: 0 5px;
      }
    }
  }
  
  .search-btn-container {
    text-align: center;
    margin: 30px 0;
  }
}

.analysis-container {
  padding: 20px;

  .statistics-cards {
    .stat-item {
      text-align: center;
      
      .stat-title {
        color: #909399;
        font-size: 14px;
        margin-bottom: 10px;
      }
      
      .stat-value {
        color: #303133;
        font-size: 24px;
        font-weight: bold;
      }
      
      .stat-sub {
        color: #606266;
        font-size: 12px;
        margin-top: 5px;
      }
    }
  }
}
</style>
